body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.LoadingScreen {
  z-index: 99;
  background-color: rgb(255, 255, 255);
  width: 100vw;
  height: 100vh;
  position: absolute;
  
}

.loadingBarText {
  position: absolute;
  width: 100%;
  top: calc(50% + 80px);
  text-align: center;
  text-transform: uppercase;
  color: #333333;
  font-family: helvetica, sans-serif;
  font-weight: bold;
}

.circle {
  position: absolute;
  width: 400px;
  height: 400px;
  left: calc(50% - 200px) ;
  top: calc(50% - 200px);
  -webkit-animation: move 1.1s infinite;
}

.cross {
  position: absolute;
  height: 400px;
  width: 400px;
  left: calc(50% - 215px) ;
  top: calc(50% - 200px);
  -webkit-animation: move2 1.1s infinite;
}



.cls-1 {
  fill: #e72379;
}

@keyframes move {
  0% {
    top: calc(50% - 200px);
  }
  15% {
    left: calc(50% - 200px);
    width: 400px;
    height: 400px;
    -webkit-transform: rotate(00deg);
  }
  25% {
    left: calc(50% - 200px);
    top: calc(50% - 400px);
    width: 400px;
    height: 200px;
  }
  50% {
    width: 400px;
    height: 200px;
    -webkit-transform: rotate(0deg);
  }
  75% {
    top: 10%;
    -webkit-transform: rotate(360deg);
    width: 400px;
    height: 400px;
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes move2 {
  0% {
    
  }
  15% {
    top: calc( 50%  - 180px);
    width: 400px;
    height: 400px;
    
    -webkit-transform:  translateY(50px) translateX(50px) rotate(90deg);
  }
  25% {
    
    width: 400px;
    height: 400px;
    -webkit-transform:  translateY(50px) translateX(50px) rotate(90deg);
  }
  50% {
    -webkit-transform:  translateY(50px) translateX(50px) rotate(90deg);
    width: 400px;
    height: 400px;
  }
  75% {
    -webkit-transform:  translateY(50px) translateX(50px) rotate(90deg);
    
    width: 400px;
    height: 400px;
  }
  100% {
    -webkit-transform:  translateY(50px) translateX(50px) rotate(90deg);
  }


}